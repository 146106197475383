import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import CookieConsent from 'react-cookie-consent';

import SiteSettingsContext from '../../context/SiteSettingsContext';
import theme from '../../styles/theme';

const ConsentContainer = styled.div`
  .cookieConsent {
    padding-bottom: 2rem;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 1rem 0;
    }

    @media (min-width: ${prop('theme.breakpoints.xl')}) {
      height: 4.25rem;
      padding: 0;
    }
  }
`;

const MessageContainer = styled.p`
  color: ${prop('theme.colors.black')};
  font-weight: ${prop('theme.fontWeights.regular')};
  font-size: 1.125rem;
  line-height: 16px;
  margin-bottom: 0;
  margin-right: 1.5rem;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    padding: 1rem 2rem;
  }
`;

const propTypes = {
  link: PropTypes.string,
  message: PropTypes.string
};

const defaultProps = {
  link: '',
  message: ''
};

function CookieConsentAlert({ link, message }) {
  const {
    global: { cookie_policy }
  } = useContext(SiteSettingsContext);

  // react-cookie-consent uses inline styles, so the best way we can override those is also using inline styles
  // this is why we are not using a styled component here
  return (
    <ConsentContainer>
      <CookieConsent
        contentStyle={{ margin: '0' }}
        buttonStyle={{
          background: theme.colors.redPrimary,
          borderRadius: '2.125rem',
          color: theme.colors.white,
          fontSize: '14px',
          fontWeight: theme.fontWeights.bold,
          letterSpacing: '2px',
          padding: '0.75rem 3rem',
          textTransform: 'uppercase',
          margin: '0'
        }}
        style={{
          background: theme.colors.cookieConsentBackgroundGrey,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <MessageContainer>
          {message}{' '}
          <a href={cookie_policy.url} target='_blank'>
            {link}
          </a>
        </MessageContainer>
      </CookieConsent>
    </ConsentContainer>
  );
}

CookieConsentAlert.propTypes = propTypes;
CookieConsentAlert.defaultProps = defaultProps;

export default CookieConsentAlert;
